/* You can add global styles to this file, and also import other style files */




body{
  margin: 0px;
  font-family: Degular;
  background-color: #f8f4e7;
}
body * {
  outline: none;
}

.container-fluid{
  padding: 0px;
  .row{
    margin: 0px;
  }
}


.slick-list{
  img{  
    border-radius: 3px;
  }
}
.slick-dots{
  text-align: right;
  padding-right: 25px;
}
.slick-dots li{
  margin: 0px;
}
.slick-dots li button {
  overflow:hidden;
}
